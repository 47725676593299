import React from 'react'
import classNames from 'classnames'
import { useTranslation } from '../../i18n'
import { ChoiceOption } from './ChoiceOption'
import { SquareChoiceOption } from './SquareChoiceOption'
import { ColumnChoiceOption } from './ColumnChoiceOption'
import { MultiRowColumnChoiceOption } from './MultiRowColumnChoiceOption'
import { SurveyTheme } from '@contexts/survey/survey'

export interface ChoicesProps {
    name?: string
    options?: string[]
    inlineImages?: string[]
    imageOptions?: Array<{ label: string; image: string; value?: string }>
    multiImageOptions?: Array<{ label: string; images: string[]; value?: string }>
    selected?: string[]
    multiple?: boolean
    labelClassName?: string
    onClick?: (option: string) => void
    onChange?: (values: string[]) => void
    width?: string
    labelPrefix?: string
    images?: Array<string>
    inModal?: boolean
    theme?: SurveyTheme
    extendedOnboardingVariant: 'default' | 'app_onboarding'
}

/**
 * Radio Button
 */
export const Choices: React.FC<ChoicesProps> = ({
    name,
    options,
    selected = [],
    multiple = false,
    labelClassName,
    onChange,
    onClick,
    width,
    labelPrefix = '',
    inlineImages,
    imageOptions,
    multiImageOptions,
    inModal,
    theme,
    extendedOnboardingVariant,
    ...props
}) => {
    const handleOptionChange = (value: string) => {
        const index = selected.indexOf(value)
        let next: string[]
        if (index >= 0) {
            next = selected.filter(v => v !== value)
        } else {
            next = multiple ? [...selected, value] : [value]
        }
        onChange(next)
    }

    const { t, i18n } = useTranslation()

    if (extendedOnboardingVariant === 'app_onboarding') {
        if (multiImageOptions) {
            return (
                <div className="w-full px-4 flex flex-col gap-y-2">
                    {multiImageOptions.map((option, i) => {
                        const { label, images, value } = option
                        const optionValue = value ?? label
                        const translatedLabel = i18n.exists(`survey:${name}.options.${label}.title`)
                            ? t(`survey:${name}.options.${label}.title`, { context: theme })
                            : `${labelPrefix}${t(label)}`
                        const translatedDescription = i18n.exists(`survey:${name}.options.${label}.description`)
                            ? t(`survey:${name}.options.${label}.description`, { context: theme })
                            : undefined
                        return (
                            <MultiRowColumnChoiceOption
                                key={label}
                                title={translatedLabel}
                                description={translatedDescription}
                                checked={selected.includes(optionValue)}
                                onClick={() => handleOptionChange(optionValue)}
                                style={{ animationDelay: `${i * 75}ms` }}
                                images={images}
                            />
                        )
                    })}
                </div>
            )
        } else {
            return (
                <div className="w-full px-4 flex flex-col gap-y-2">
                    {imageOptions.map((option, i) => {
                        const { label, image, value } = option
                        const optionValue = value ?? label
                        const translatedLabel = i18n.exists(`survey:${name}.options.${label}`)
                            ? t(`survey:${name}.options.${label}`, { context: theme })
                            : `${labelPrefix}${t(label)}`
                        return (
                            <ColumnChoiceOption
                                key={label}
                                label={translatedLabel}
                                checked={selected.includes(optionValue)}
                                onClick={() => handleOptionChange(optionValue)}
                                style={{ animationDelay: `${i * 75}ms` }}
                                image={image}
                                checkbox={multiple}
                            />
                        )
                    })}
                </div>
            )
        }
    } else if (imageOptions) {
        return (
            <div className="flex justify-center w-full overflow-x-hidden">
                <div
                    className={classNames(
                        'grid grid-cols-2 gap-3 p-1',
                        imageOptions.length % 3 === 0 || imageOptions.length > 4 ? 'md:grid-cols-3' : '',
                    )}
                >
                    {imageOptions.map((option, i) => {
                        const { label, image, value } = option
                        const optionValue = value ?? label
                        const translatedLabel = i18n.exists(`survey:${name}.options.${label}`)
                            ? t(`survey:${name}.options.${label}`, { context: theme })
                            : `${labelPrefix}${t(label)}`
                        return (
                            <SquareChoiceOption
                                key={label}
                                label={translatedLabel}
                                checked={selected.includes(optionValue)}
                                onClick={() => handleOptionChange(optionValue)}
                                style={{ animationDelay: `${i * 75}ms` }}
                                image={image}
                                checkbox={multiple}
                            />
                        )
                    })}
                </div>
            </div>
        )
    } else {
        return (
            <div className={`grid gap-y-4 grid-cols-1 ${width} overflow-x-hidden p-1`} {...props}>
                {options.map((option, i) => {
                    const label = i18n.exists(`survey:${name}.options.${option}`)
                        ? t(`survey:${name}.options.${option}`, { context: theme })
                        : `${labelPrefix}${t(option)}`
                    return (
                        <ChoiceOption
                            key={option}
                            label={label}
                            inlineImage={inlineImages && inlineImages[i]}
                            checked={selected.includes(option)}
                            onClick={onClick ? () => onClick(option) : () => handleOptionChange(option)}
                            className="animate-fade-in-left"
                            labelClassName={labelClassName}
                            style={{ animationDelay: `${i * 75}ms` }}
                            checkbox={multiple}
                        />
                    )
                })}
            </div>
        )
    }
}
