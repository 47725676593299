import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { LoadingSpinner } from '@components/common/LoadingSpinner'
import { useSurvey, QuestionProvider, loadSurvey, Answers } from '../../contexts/survey'
import { Button } from '@components/common/Button'
import { GetStaticPaths, GetStaticProps } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { SelectStep } from '../../components/steps/SelectStep'
import { includeDefaultNamespaces, useTranslation } from '../../i18n'
import { setAmplitudeUserId, setAmplitudeUserProperty } from '../../services/client/amplitude'
import { useAuth } from '../../contexts/auth'
import { signInAnonymously, User } from '../../contexts/auth/auth'
import SurveyHeader from '@components/common/SurveyHeader'
import { trackScreenView } from '@services/client/events'
import { MilestoneStepsProgress } from '@components/common/MilestoneStepsProgress'
import { Statsig, useExperiment } from 'statsig-react'
import { useGeo, withGeo } from '@contexts/geo'
import { withSubscription } from '@contexts/subscription'
import { withPayment } from '@contexts/payment'
import { FakeProgressBar } from '@components/common/ProgressBar'
import Image from 'next/image'
import { SurveyStateInitialized, SurveyTheme, surveyThemes } from '@contexts/survey/survey'
import { convertFeetInchesToCm, convertLbsToKg } from '../../utils/convert'

import getSurveyConfig from '../../components/steps/surveyConfig'
import getSurveyConfigV2 from '../../components/steps/surveyConfigV2'
import getSurveyConfigFunnelFox from '../../components/steps/surveyConfigFunnelFox'

import { Question } from '../../contexts/survey/question'

const Survey: React.FC = () => {
    const router = useRouter()
    const [user, authInitialized] = useAuth()
    const [surveyState, dispatch] = useSurvey()
    const { t, i18n } = useTranslation()
    const [isLoaderDone, setLoaderDone] = useState<Boolean>(false)
    const initialStep = useRef<string>()
    const iniitalQueryParams = useRef({})
    const geo = useGeo()

    const emailStepVariant = useExperiment('email_step_variant').config.getValue('variant', 'disabled') as
        | 'disabled'
        | 'skippable'
        | 'forced'
    const firstQuestion = useExperiment('survey_first_question').config.getValue('question', 'goal') as
        | 'goal'
        | 'gender'

    //Not in use anymore
    const onboardingVariant = useExperiment('onboarding_variant').config.getValue('variant', 'default') as
        | 'default'
        | 'personalized'
    const socialProofVariant = useExperiment('social_proof_variant').config.getValue('variant', 'default') as
        | 'default'
        | 'social_proof'

    const extendedOnboardingVariant = useExperiment('app_onboarding').config.getValue('variant', 'default') as
        | 'default'
        | 'app_onboarding'

    useEffect(() => {
        const { step, ...initialQuery } = router.query
        initialStep.current = step as string
        iniitalQueryParams.current = initialQuery
    }, [])

    useEffect(() => {
        if (geo.initialized && surveyState.initialized) {
            dispatch({
                type: 'QUESTION_ANSWER_CHANGED',
                name: 'country',
                value: geo.countryCode,
            })
            dispatch({
                type: 'QUESTION_ANSWER_CHANGED',
                name: 'currency',
                value: geo.currency,
            })
            dispatch({
                type: 'QUESTION_ANSWER_CHANGED',
                name: 'timezone',
                value: geo.timezone,
            })
        }
    }, [geo.initialized, surveyState.initialized])

    const getSurveyTheme = (): SurveyTheme => {
        const q = ((router.query['theme'] as string) ?? '').toLowerCase().trim()

        return surveyThemes.includes(q as SurveyTheme) ? (q as SurveyTheme) : 'fasting'
    }

    const surveyTheme = getSurveyTheme()

    //see if survey theme already includes gender
    const getGenderFromTheme = (): string | undefined => {
        const q = ((router.query['theme'] as string) ?? '').toLowerCase().trim()

        if (q.includes('men')) return 'male'
        if (q.includes('women') || q.includes('woman')) return 'female'
        return undefined
    }

    const getDataFromURL = (): Record<string, string | undefined> => {
        const urlParams = new URLSearchParams(router.asPath.split('?')[1])
        return {
            weight: urlParams.get('weight') || undefined,
            targetWeight: urlParams.get('target_weight') || undefined,
            age: urlParams.get('age') || undefined,
            height: urlParams.get('height') || undefined,
            gender: urlParams.get('gender') || undefined,
            goal: urlParams.get('goal') || undefined,
            measurementSystem: urlParams.get('measurement_system') || undefined,
        }
    }

    const externalFunnelFromURL = (): string | undefined => {
        const q = ((router.query['funnel'] as string) ?? '').toLowerCase().trim()
        return q
    }

    const gender = getGenderFromTheme()
    const funnel = externalFunnelFromURL()
    const urlData = getDataFromURL()

    // load the user survey, pass already prefilled answers
    const initUserSurvey = async (user: User) => {
        const answers = await loadSurvey(user.id)

        const experiements = {
            emailStepVariant: emailStepVariant,
            firstQuestion: firstQuestion,
            onboardingVariant: onboardingVariant,
            extendedOnboardingVariant: extendedOnboardingVariant,
        }

        const updatedAnswers: Answers = {
            ...answers,
            locale: i18n.language,
            utc_offset: new Date().getTimezoneOffset() * -1,
            ...(gender && { gender: [gender] }),
        }

        // Convert and add data from URL
        if (urlData.measurementSystem === 'imperial') {
            if (urlData.weight) {
                updatedAnswers.weight = convertLbsToKg(parseFloat(urlData.weight))
            }
            if (urlData.targetWeight) {
                updatedAnswers.target_weight = convertLbsToKg(parseFloat(urlData.targetWeight))
            }
            if (urlData.height) {
                const [feet, inches] = urlData.height.split("'").map(parseFloat)
                updatedAnswers.height = convertFeetInchesToCm({ feet, inches })
            }
        } else {
            if (urlData.weight) updatedAnswers.weight = parseFloat(urlData.weight)
            if (urlData.targetWeight) updatedAnswers.target_weight = parseFloat(urlData.targetWeight)
            if (urlData.height) updatedAnswers.height = parseFloat(urlData.height)
        }

        if (urlData.age) updatedAnswers.age = parseInt(urlData.age)
        if (urlData.gender) updatedAnswers.gender = [urlData.gender]
        if (urlData.goal) updatedAnswers.goal = urlData.goal
        if (urlData.measurementSystem) updatedAnswers.measurement_system = urlData.measurementSystem

        const allParametersPresent = [
            'weight',
            'targetWeight',
            'age',
            'height',
            'gender',
            'goal',
            'measurementSystem',
        ].every(param => urlData[param] !== undefined)

        const surveyConfig =
            funnel === 'funnelfox' && allParametersPresent
                ? getSurveyConfigFunnelFox(surveyTheme, experiements, updatedAnswers)
                : extendedOnboardingVariant === 'app_onboarding'
                ? getSurveyConfigV2(surveyTheme, experiements, updatedAnswers)
                : getSurveyConfig(surveyTheme, experiements, updatedAnswers)

        dispatch({
            type: 'SURVEY_LOADED',
            uid: user.id,
            theme: surveyTheme,
            experiements,
            config: surveyConfig,
            answers: updatedAnswers,
            initialPath: initialStep.current?.toString(),
        })
    }

    // wait for initial auth update
    useEffect(() => {
        if (user) {
            setAmplitudeUserId(user.id)
            setAmplitudeUserProperty('exp_email_step_variant', emailStepVariant)
            setAmplitudeUserProperty('exp_first_question', firstQuestion)
            setAmplitudeUserProperty('exp_extended_onboarding_variant', extendedOnboardingVariant)
            initUserSurvey(user)
        } else if (authInitialized && !user) {
            signInAnonymously()
        }
    }, [user?.id, authInitialized])

    // track screen views
    useEffect(() => {
        if (surveyState.initialized && surveyState.currentQuestion) {
            trackScreenView(surveyState.currentQuestion.path)
        }
    }, [surveyState.initialized, (surveyState as SurveyStateInitialized).nextQuestion])

    // upaate statsig user
    useEffect(() => {
        if (surveyState.initialized) {
            Statsig.updateUser({
                userID: user?.id,
                custom: surveyState.answers,
            })
        }
    }, [user?.id, surveyState.initialized, (surveyState as SurveyStateInitialized).answers])

    // prefetch next question to make things faster
    useEffect(() => {
        if (surveyState.initialized && surveyState.nextQuestion) {
            router.prefetch(`/survey/${surveyState.nextQuestion.path}`)
        }
    }, [surveyState.initialized, (surveyState as SurveyStateInitialized).nextQuestion])

    // update the current route if question has changed
    useEffect(() => {
        const { step: currentStepQuery } = router.query
        if (
            surveyState.initialized &&
            surveyState.prevQuestion?.path !== surveyState.currentQuestion?.path &&
            currentStepQuery?.toString() !== surveyState.currentQuestion?.path
        ) {
            router.push(
                {
                    pathname: '/survey/[[...step]]',
                    query: { step: surveyState.currentQuestion.path, ...iniitalQueryParams.current },
                },
                undefined,
            )
        }
    }, [
        surveyState.initialized,
        (surveyState as SurveyStateInitialized).currentQuestion,
        (surveyState as SurveyStateInitialized).prevQuestion,
    ])

    const handleRouteChange = (url: string, opts) => {
        if (
            url.includes('/survey/') &&
            surveyState.initialized &&
            surveyState.currentQuestion &&
            !(url as string).includes(surveyState.currentQuestion.path)
        ) {
            const requestedStep = (url as string).substring((url as string).lastIndexOf('/') + 1)
            const questionToSet = surveyState.config.questions.findIndex(q => q.path === requestedStep)
            if (questionToSet >= 0) {
                dispatch({
                    type: 'SET_QUESTION',
                    path: surveyState.config.questions[questionToSet].path,
                })
            } else {
                router.replace({
                    pathname: '/survey/[[...step]]',
                    query: { step: surveyState.currentQuestion.path, ...iniitalQueryParams.current },
                })
            }
        }
    }

    // handle route changes (back button)
    useEffect(() => {
        router.events.on('routeChangeStart', handleRouteChange)
        return () => {
            router.events.off('routeChangeStart', handleRouteChange)
        }
    }, [(surveyState as SurveyStateInitialized).currentQuestion])

    const handleStepBack = (e: React.MouseEvent<HTMLButtonElement>) => {
        ;(e.target as HTMLButtonElement).blur()
        dispatch({ type: 'QUESTION_STEP_BACK' })
        document.getElementById('step-main-content').scrollTop = 0
    }

    const handleStepNext = (e: React.MouseEvent<HTMLButtonElement>) => {
        ;(e.target as HTMLButtonElement).blur()
        dispatch({ type: 'QUESTION_COMPLETED' })
        document.getElementById('step-main-content').scrollTop = 0
    }

    const onLoaderDone = () => {
        setLoaderDone(true)
    }

    //Possible loading screen images
    const themeImages = {
        foodscanner: { src: '/images/landingpage/fasty_scanner.svg', width: 146, height: 128, alt: 'Food Scanner' },
        'women-fasting': {
            src: '/images/landingpage/women-fasting.png',
            width: 346,
            height: 328,
            alt: 'Women Fasting',
        },
        tiktok: { src: '/images/landingpage/fasty_scanner.svg', width: 146, height: 128, alt: 'Food Scanner' },
        'women-fasting2': {
            src: '/images/landingpage/women-fasting.png',
            width: 346,
            height: 328,
            alt: 'Women Fasting',
        },
        'men-fasting': { src: '/images/landingpage/men-fasting.png', width: 346, height: 328, alt: 'Men Fasting' },
        'beginners-keto': {
            src: '/images/landingpage/beginners-keto.png',
            width: 346,
            height: 328,
            alt: 'Beginners Keto',
        },
        'women-keto': { src: '/images/landingpage/women-keto.png', width: 346, height: 328, alt: 'Woman Keto' },
        'men-keto': { src: '/images/landingpage/men-keto.png', width: 346, height: 328, alt: 'Men Keto' },
    }

    const defaultImage = {
        src: '/images/landingpage/weight-loss.svg',
        width: 640,
        height: 640,
        alt: 'Default Weight Loss',
    }

    const imageProps = themeImages[surveyTheme as keyof typeof themeImages] || defaultImage

    const isDefaultImage = !Object.keys(themeImages).includes(surveyTheme)

    // show loading screen
    if (!surveyState.initialized || !isLoaderDone) {
        return (
            <>
                <Head>
                    <title>{t('common:pagetitle')}</title>
                </Head>
                <div className={'absolute inset-0 overflow-x-hidden flex flex-col'}>
                    <SurveyHeader showBackButton={false} showNextButton={false} onStepBack={null} />
                    <div id="step-main-content" className="flex-1 pb-3 relative">
                        <div className="flex flex-col h-full max-w-xl w-full mx-auto px-4 justify-start items-center">
                            <Image className={`mb-4 ${isDefaultImage ? 'h-2/6 mt-4' : ''}`} {...imageProps} />
                            <h2
                                className={classNames(
                                    'text-center text-2xl leading-tight font-bold text-boost1 w-52',
                                    surveyTheme === 'foodscanner' ? 'mb-4' : 'mb-6',
                                )}
                            >
                                {t('survey:loading.title', { context: surveyTheme })}
                            </h2>
                            <h3
                                className={classNames(
                                    'text-center text-3xl leading-tight font-bold text-dark1',
                                    surveyTheme === 'foodscanner' ? 'text-xl mb-4' : 'text-3xl mb-8',
                                )}
                            >
                                {t('survey:loading.subtitle', { context: surveyTheme })}
                            </h3>
                            {surveyTheme === 'foodscanner' && (
                                <Image
                                    className="mb-4 rounded-xl"
                                    src="/images/scanner_preview.gif"
                                    width={300}
                                    height={169}
                                    alt="food scanner"
                                />
                            )}
                            <div className="w-full">
                                <div className="flex items-center justify-center">
                                    <span className="text-base text-dark2">{t('survey:loading.loader')}</span>
                                    <div className="ml-3">
                                        <LoadingSpinner size="sm" />
                                    </div>
                                </div>
                                <FakeProgressBar duration={5} onEnd={onLoaderDone} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const renderCurrentQuestion = () => {
        if (!surveyState.initialized || !surveyState.currentQuestion) return null

        const currentQuestion = surveyState.currentQuestion as Question & {
            shouldRender?: (answers: Answers) => boolean
        }

        if (currentQuestion.shouldRender && !currentQuestion.shouldRender(surveyState.answers)) {
            // Skip this question and move to the next one
            dispatch({ type: 'QUESTION_COMPLETED' })
            return null
        }

        switch (currentQuestion.type) {
            case 'select':
                return <SelectStep extendedOnboardingVariant={extendedOnboardingVariant} />
            case 'custom':
                return currentQuestion.component
            default:
                return null
        }
    }

    const pageClassName = classNames(
        'absolute inset-0 overflow-x-hidden bg-[#FAF8F7] flex flex-col',
        surveyState.currentQuestion.pageClassName,
    )

    const showBottomButton = !surveyState.currentQuestion.hideButtonBar && !surveyState.currentQuestion.completeOnSelect

    return (
        <>
            <Head>
                <title>{t('common:pagetitle')}</title>
            </Head>
            <div className={pageClassName}>
                {!surveyState.currentQuestion.hideHeader && (
                    <SurveyHeader
                        showBackButton={surveyState.prevQuestion && !surveyState.currentQuestion.hideBackHeader}
                        showNextButton={surveyState.currentQuestion.showNextHeader}
                        onStepBack={surveyState.prevQuestion ? handleStepBack : null}
                    />
                )}
                {!surveyState.currentQuestion.hideStepper && (
                    <div className="w-full max-w-lg mx-auto px-4 flex items-center justify-center mb-8">
                        <MilestoneStepsProgress
                            steps={surveyState.milestoneStepper.totalSteps}
                            current={surveyState.milestoneStepper.step}
                            progress={surveyState.milestoneStepper.progress}
                        />
                    </div>
                )}

                <div id="step-main-content" className={classNames('flex flex-col pb-3', showBottomButton && 'mb-16')}>
                    <div className="flex flex-col relative">
                        <QuestionProvider
                            question={surveyState.currentQuestion}
                            answers={surveyState.answers}
                            dispatch={dispatch}
                        >
                            {renderCurrentQuestion()}
                        </QuestionProvider>
                    </div>
                </div>

                {showBottomButton && (
                    <div className="fixed bottom-0 w-full py-3 md:pb-14">
                        <div className="flex items-center justify-center max-w-lg mx-auto px-4">
                            <Button
                                disabled={!surveyState.canStepNext}
                                primary
                                label={
                                    surveyState.currentQuestion.buttonNextTitle
                                        ? t(surveyState.currentQuestion.buttonNextTitle)
                                        : t('common:controls.next')
                                }
                                className="w-full md:max-w-4/5"
                                onClick={handleStepNext}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
    const defaultConfig = getSurveyConfig(
        'fasting',
        {
            emailStepVariant: 'skippable',
            firstQuestion: 'goal',
            onboardingVariant: 'default',
            extendedOnboardingVariant: 'default',
        },
        {},
    )

    const v2Config = getSurveyConfigV2(
        'fasting',
        {
            emailStepVariant: 'skippable',
            firstQuestion: 'goal',
            onboardingVariant: 'default',
            extendedOnboardingVariant: 'app_onboarding',
        },
        {},
    )

    const paths = locales.reduce(
        (acc, locale) => [
            ...acc,
            {
                params: {
                    step: false,
                },
                locale,
            },
            ...defaultConfig.questions.map(q => ({
                params: {
                    step: [q.path],
                },
                locale,
            })),
            ...v2Config.questions.map(q => ({
                params: {
                    step: [q.path],
                },
                locale,
            })),
        ],
        [],
    )

    return { paths, fallback: true }
}

export const getStaticProps: GetStaticProps = async context => {
    return {
        props: {
            ...(await serverSideTranslations(
                context.locale,
                includeDefaultNamespaces(['survey', 'sign_in', 'common', 'account']),
            )),
        },
    }
}

export default withGeo(withSubscription(withPayment(Survey)))
